import React from 'react'
import './Contact.css'

/*images*/
//import Gmap from '../../assets/images/contactUs/image-1.jpg'
import insta from '../../assets/images/contactUs/imageInsta.jpg'
import face from '../../assets/images/contactUs/imageFace.jpg'


const Contact = () => {
  return (
    <a href='https://www.google.com/maps/place/Reshmi+Eye+Care+%26+Maternity+Centre/@17.3322618,76.8301133,15z/data=!4m6!3m5!1s0x3bc8c756ea333789:0x53b3bf5dac13745b!8m2!3d17.3322618!4d76.8301133!16s%2Fg%2F11hbhk0f4m?entry=ttu'>
    <div className="contactSection">
      <div className="divSection">
        <div className="heading">Contact Us</div>
        <div className='headerContact'>
          <p className="p">Have a Question? Get In Touch</p>
          <p className="contacatDetails">
            Reshmi Hospital is located in the Land of Toordal, Kalaburgi. The hospital is situated in Sharan Nagar, a prominent
            residential and commercial area of Kalaburgi, well known for its broad roads lined with lush green trees.
          </p>
        </div>
        <div className='main-body'>
          <div className='contDet'>
            <div className='address'>
              <div className="localAddress">Address</div>
              <p className="detailedAddress">
                <span className="span">
                  3,5th Cross,
                  <br />
                  Sharan Nagar,
                  <br />
                  Kalaburgi 585103
                  <br />
                  Landmark:Opposite to NV Ground
                  <br />
                  Phone:08472-275556
                  <br />
                  Email:vkorwar@yahoo.com
                </span>
              </p>
            </div>
            <div className="consultant">
              <div className="mainConsultant">Consultation</div>
              <div className="entry">
                <span className='hehe'>By Appointment Or WalkIn</span>
                <p className='hours'>
                  12pm to 4pm and 6pm to 8pm<br />
                  Monday to Saturday<br />
                  Sunday holiday. Emergency appointment only<br />
                </p>
              </div>
            </div>
            <div className='timing'>
              <div className="visiting">Ward Visiting Hour</div>
              <div className="watchHours">12:00PM t0 8:00PM</div>
            </div>
            <div className='socialMedia'>
              <div className="mainMedia">Our Social Media Links</div>
              <div className='social-Links'>
                <a href='https://www.facebook.com/recmc.org/'>
                  <img className="imgFace" alt="IimageFacebook" src={face} />
                </a>
                <img className="imgInsta" alt="imageInsta" src={insta} />

              </div>
            </div>
          </div>
          <div className='imgdiv'>
          {/* <a href='https://www.google.com/maps/place/Reshmi+Eye+Care+%26+Maternity+Centre/@17.3322618,76.8301133,15z/data=!4m6!3m5!1s0x3bc8c756ea333789:0x53b3bf5dac13745b!8m2!3d17.3322618!4d76.8301133!16s%2Fg%2F11hbhk0f4m?entry=ttu'>
        <img className="imgMap" alt="" src='../../' />
      </a> */}
          </div>
        </div>
      </div>
    </div>
    </a>
  )
}

export default Contact