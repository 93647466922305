import React, { PureComponent } from 'react'
import "./Footer.css"

export class Footer extends PureComponent {
  render() {
    return (
      <div className="footer">
        <p>&#169; 2024 - Reshmi Hospital, All Rights Reserved. Designed and Developed by <span><a href='https://belageri.com'>Belageri Technologies.</a></span></p>
      </div>
    )
  }
}

export default Footer
