import React from 'react'
import './Header.css'
import Logo from '../../assets/reshmi-logo.jpeg'
import Logo2 from '../../assets/reshmilogo2.jpg'




const Header = () => {

  return (
    <section className='headerContainer'>
      <div className='header flex'>
        <div className='logoDiv flex'>
          <img alt='Hospital-Logo' src={Logo} />
        </div>
        <div className='title-desktop flex'>
          Reshmi Eye Care & Maternity Hospital
        </div>
        <div className='logoDiv flex'>
          <img alt='Hospital-Logo' src={Logo2} />
        </div>
        <div className='title-mobile'>
          RECMH
        </div>
      </div>
    </section>
  )
}

export default Header