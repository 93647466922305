import React from 'react';
import './Ourteam.css'; // Make sure the correct path is used for the CSS file.
import veerImg from '../../assets/images/ourteam/Dr.veeresh.jpg';
import sheetalImg from '../../assets/images/ourteam/Dr.Sheetal.jpg';

const Data = [
  {
    imgSrc: veerImg,
    doctorName: 'Dr Veeresh V Korwar',
    Special: 'MS(Ophthalmology)',
    moreInfo: ' Graduated from M.R. Medical College Kalaburagi (2007), did Postgraduation in ophthalmology at Pravara Institute of medical sciences one of the premiere institute in Maharashtra (2008 - 2011). Presently working as Assistant Prof. Dept. of ophthalmology M.R. Medical college and incharge of HKE\'s Eye Bank and Keratoplasty Centre Basaveshwar Teaching and General Hospital, Kalaburagi'
  },
  {
    imgSrc: sheetalImg,
    doctorName: 'Dr Sheetal V Korwar',
    Special: 'Gynaecologist & Obstetrician',
    moreInfo: 'More Details.....'
  }
  //   {
  //      imgSrc: img3,
  //     doctorName: 'Dr Sheetal V Korwar',
  //     Special: 'Gynaecologist &amp; Obstetrician',
  //     moreInfo: 'More Details.....'
  //   },
  //   {
  //      imgSrc: img4,
  //     doctorName: 'Dr Sheetal V Korwar',
  //     Special: 'Gynaecologist & Obstetrician',
  //     moreInfo: 'More Details.....'
  //   }
]
const Ourteam = () => {
  return (
    <div className="teamSection flex">
      <div className="main-heading">
        Our Doctors Team
      </div>
      <div className="doctorTeam">
        {
          Data.map(({ imgSrc, doctorName, Special, moreInfo }) => {
            return (
              <div className='doctor-1 '>
                <div className="doctorNameSection-1">
                  <img className="veerImg" alt="image1" src={imgSrc} />
                  <div className="doctorName-1"><h3>{doctorName}</h3></div>
                  <div className="specialist-1"><h5>{Special}</h5></div>
                </div>
                <div className='detailsOfDoctor-1'>
                  <p className="doctorInfo">
                    {moreInfo}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>

      {/* <div className='doctor-2'>
          <div className="doctorNameSection-2">
            <img className="sheetalImg" alt="image2" src="image-2.png" />

            <div className="doctorName-2">Dr Sheetal V Korwar</div>
            <div className="specialist-2">Gynaecologist &amp; Obstetrician</div>
          </div>
          <div className="detailsOfDoctor-2">More Details......</div>
        </div> */}
    </div>
  );
};

export default Ourteam;
