import React from 'react'
import hny from '../../assets/images/gallery/HappyNewYear.jpeg'
import img1 from '../../assets/images/gallery/img-1.jpg'
import img2 from '../../assets/images/gallery/img-2.jpg'
import img3 from '../../assets/images/gallery/img-3.jpg'
import img4 from '../../assets/images/gallery/img-4.jpg'
import img5 from '../../assets/images/gallery/img-5.jpg'
import img6 from '../../assets/images/gallery/img-6.jpg'
import img7 from '../../assets/images/gallery/img-7.jpg'
import img8 from '../../assets/images/gallery/img-8.jpg'
import img9 from '../../assets/images/gallery/img-9.jpg'
import img10 from '../../assets/images/gallery/img-10.jpg'

import './Gallery.css'


const Gallery = () => {
    return (
        <div className='gallerySection'>
            <div className='main-heading'>Gallery</div>
            <div className='img-section grid'>
                {/* <img className='img' alt='h0' src={hny} /> */}
                <img className='img' alt='h0' src={img1} />
                <img className='img' alt='h1' src={img2} />
                <img className='img' alt='h2' src={img3} />
                <img className='img' alt='h3' src={img4} />
                <img className='img' alt='h4' src={img5} />
                <img className='img' alt='h5' src={img6} />
                <img className='img' alt='h6' src={img7} />
                <img className='img' alt='h7' src={img8} />
                <img className='img' alt='h8' src={img9} />
                <img className='img' alt='h9' src={img10} />


            </div>
        </div>
    )
}

export default Gallery;