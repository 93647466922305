import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import './Review.css'
//import QuoteImg from '../../assets/images/review/quote.png'


const AutoplaySlider = withAutoplay(AwesomeSlider);

const Review = () => {
    return (
        <div className='sildecontainer flex'>
            <AutoplaySlider className='sliding' style={{ "--slider-height-percentage": "40%", "background-size": "cover", "height": "530px", "background-repeat": "no-repeat", "background-position": "center", "object-fit": "cover" }}
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={3000}
            >
                <div className='one-review'>
                <div className='review'> What our patient says?</div>
                    <div className='docName'>Dr.Veeresh V Korwar</div>
                    <img className='qoute-img' src={require('../../assets/images/review/quote.png')} alt='Quote' /> 
                    <div className='preivew'>I have visited multiple hospitals in Kalaburagi for my father's eye cataract surgery but we did not receive enough confidence from them to get an operation there. After visiting Reshmi Eye Care Dr. Veeresh sir explained in detail about the surgery process and available lens options. After the surgery within a week my father back to his normal routine. And the hospital has provided all the necessary documents to get the reimbursement from medical insurance. I can say Reshmi Eye Care is one of the best eye hospitals in Kalaburagi. Thanks to Dr. Veeresh sir and his team.</div>
                    <div className='pName'>Mallareddy</div>
                </div>
                <div className='two-review'>
                <div className='review'> What our patient says?</div>
                    <div className='docName'>Dr.Sheetal V Korwar</div>
                    <img className='qoute-img' src={require('../../assets/images/review/quote.png')} alt='Quote' />
                    <div className='preivew'>Dr. Sheetal is an amazing maternity doctor! The personalized care and dedication to my well-being were evident at every appointment. Dr. Sheetal took the time to address all my concerns and provided valuable guidance throughout my pregnancy journey. The friendly staff and welcoming atmosphere at the clinic added to the overall positive experience. I highly recommend Dr. Sheetal to all expectant mothers.</div>
                    <div className='pName'>Kavya</div>
                </div>

            </AutoplaySlider>
        </div>
    );
};


export default Review;