import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Navigationbar.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai'
import { TbGridDots } from 'react-icons/tb'


function Navigationbar() {
  /*code to show navbar */
  const [active, setActive] = useState('navbar')
  const showNav = () => {
    setActive('navbar activeNavbar')
  }
  /*code to remove navbar*/
  const removeNav = () => {
    setActive('navbar')
  }
  return (
    <div className='navbarSection'>
      <Navbar className={active}bg="dark" data-bs-theme="dark">
        <Container>
          <Nav className="navbarContent ">
            <Link className='navItem' to='/'>Home</Link>

            {/* <Nav.Link className='navItem' to="/">Home</Nav.Link> */}
            <Link className='navItem' to='/aboutus'>About Us</Link>
            <Link className='navItem' to='/team'>Our Team</Link>
            {/* <Nav.Link className='navItem' to="/Specialists">Specialties</Nav.Link> */}
            <Link className='navItem' to='/Specialists'>Specialites</Link>
            <Link className='navItem' to='/gallery'>Gallery</Link>
            {/* <Nav.Link className='navItem' to="/contact">Contact Us</Nav.Link> */}
            <Link className=" navItem" to='/contact'>Contact Us</Link>
            <div onClick={removeNav} className='closeNavbar'>
              <AiFillCloseCircle className='icon' />
            </div>
          </Nav>
        </Container>
      </Navbar>
      <div onClick={showNav}  className='toggleNavbar'>
        <TbGridDots className='icon' />
      </div>
    </div>
  );  
}

export default Navigationbar;
