import React, { PureComponent } from 'react'

import Slide from '../Slide/Slide';

import Specialists from '../Specialists/Specialists';
import Contact from '../Contact-Us/Contact';

import Ourteam from '../Ourteam/Ourteam';
import About from '../About/About';
import Gallery from '../Gallery/Gallery';
import Review from '../Review/Review';

export class Home extends PureComponent {
  render() {
    return (
      <>
        <Slide />
        <About />
        <Ourteam /> 
        <Specialists />
        <Gallery />
        <Review />
        <Contact /> 
        {/* <Footer /> */}
      </>
    )
  }
}

export default Home
