import ReactDOM from "react-dom/client";
import React from 'react';
import Header from './components/Header/Header';
import Navigationbar from './components/Navigationbar/Navigationbar';
import Specialists from './components/Specialists/Specialists';
import Contact from './components/Contact-Us/Contact';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import About from "./components/About/About";
import Ourteam from "./components/Ourteam/Ourteam";
import Gallery from "./components/Gallery/Gallery";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Navigationbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/team" element={<Ourteam />} />
        <Route path="/Specialists" element={<Specialists />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} /> 

      </Routes>
      <Footer />
    </div>
  );
}


export default App;