import React from 'react'
import './Specialists.css'


/*images*/
import img1 from '../../assets/images/specialist/img-1.jpg'
import img2 from '../../assets/images/specialist/img-2.jpg'

// const Data = [
//   {
//     imgSrc: img1,
//     speciality: 'Opthalmology',
//     uses: 'At our center, we treat a wide variety of conditions that affect the eye.Our surgeon ensure proper treatment of various disorders ranging from simple pain to more complex condition.'

//   },
//   {
//     imgSrc: img2,
//     doctorName: 'Dr Sheetal V Korwar',
//     Special: 'Gynaecologist & Obstetrician',
//     moreInfo: 'More Details.....'
//   }
// ]

const Specialists = () => {
  return (
    <section className='specialistsSection'>
      <div className='mainPageHeading'>
        Specialists
      </div>
      <div className='main-content'>
        <div className='one-special'>
          <img className="bed" src={img1} alt=""/>
          <div className='treatment'>
            <div className='special-Under'>Opthalmology</div>
            <p> At our center, we treat a wide variety of conditions that affect the eye.Our surgeon ensure proper treatment
              of various disorders ranging from simple pain to more complex condition.
            </p>
            <ul>
              <li>Computerized Eye Testing.</li>
              <li>Phacomumulsification Cataract Surgery (No Suture No Infection)</li>
              <li>Coreneal Transplantation</li>
              <li>Pterygium Surgery</li>
              <li>Ocular Trauma</li>
              <li>Chemical Injury Management</li>
            </ul>
          </div>
        </div>
        <div className='two-special'>
          <img className="doc" src={img2} alt=""/>
          <div className='treatment'>
            <div className='special-Under'>Gynaecology</div>
            <p>Dealing with gynecological problems can induce anxiety. This is why at our center, we combine specialists with
              advanced technology to make sure our patients are in good hands.<br />
              We carry out both laprascopic and open surgeries such as&#58;<br />
            </p>
            <ul>
              <li>Hysterectomy - Removal of uterus.</li>
              <li>Myomectomy - Removal of non cancerous tumors or fibroids</li>
              <li>Polypectomy - Removal of abnormal tissue growth.</li>
              <li>Dilation and curettage - Widening and removal of part of the lining of the uterus.</li>
            </ul>
          </div>
        </div>
        <div className='three-special'>
          <img className="bed" src={img1} alt=""/>
          <div className='treatment'>
            <div className='special-Under'>Obstetrics &amp; Post Natal care</div>
            <p>Having a stress free birthing experience is crucial. At our center we are well equipped to deal with the
              following:
            </p>
            <ul>
              <li>Normal delivery</li>
              <li>C-section</li>
              <li>Surgery for ectopic pregnancy (laprascopic and open surgery)</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Specialists