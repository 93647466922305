import React from 'react'
import './About.css'
import imgAb from '../../assets/images/about/aboutimg.jpeg'
const About = () => {
  return (
    <div className='aboutSection'>
      <span className='main-heading'>About Us
      </span>
      <div className='img-div'>
        <img className='img-about' src={imgAb} alt=""/>
      </div>
      <div className='content-hospital'>
        <div className='firstParagraph'>
          <p>Our centre is named after the legendary ophthalmologist Dr C S Reshmi (INDO AMERICAN CORNEA SURGEON)<br />
          Reshmi eye care centre is the first HOTA approved Keratoplasty centre in Kalaburagi district.<br />
            The focus of the hospital has been and always will be that the " Patient comes first ".<br />
            </p>
        </div>
        <div className='secondParagraph'>
          <p>The hospital relies on cutting edge technology and is equipped with:</p>
          <ul>
            <li>Comfortable Deluxe patient rooms</li>
            <li>Operation Theatre</li>
            <li>Recovery wards with 24/7 support services</li>
          </ul>
        </div>
        <div className='thirdParagraph'>
          <p>Anyone who walks into the hospital is assured of prompt attention and the right treatment and care.</p>
        </div>
      </div>
    </div>
  )
}

export default About